<template>
  <div>
    <AppTitulo titulo="Administrativo" />
  </div>
</template>

<script>
import AppTitulo from '@/components/AppTitulo';

export default {
  name: 'Administrativo',
  components: {
    AppTitulo,
  },
};
</script>
